import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ReactGA from "react-ga";
import "url-search-params-polyfill";
import Loadable from "react-loadable";

import "styles/App.css";

import EnvironmentService from "service/environment/EnvironmentService";
import EnvironmentConfig from "service/environment/EnvironmentConfig";
import AuthenticationService from "service/auth/AuthenticationService";
import EventService from "service/event/EventService";
import Helper from "util/Helper";

import ProtectedRoute from "components/route/ProtectedRoute";
import ScrollToTop from "components/common/ScrollToTop";
import GlobalError from "components/common/GlobalError";

import Drawer from "components/drawer/Drawer";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NoPageFound from "modules/NoPageFound";
import DoctorDrawer from "components/drawer/DoctorDrawer";

const LoadingComponent = (props) => {
  if (props.error)
    return EventService.emit("showError", {
      message: "Error loading the module",
    });

  if (props.pastDelay) return <h3> loading... </h3>;
  return null;
};
const Employee = Loadable({
  loader: () => import("modules/misc/modules/employee"),
  loading: LoadingComponent,
});
const MedicineOrders = Loadable({
  loader: () => import("modules/ops/modules/medicine-orders"),
  loading: LoadingComponent,
});
const PdfDialog = Loadable({
  loader: () =>
    import(
      "./modules/ops/modules/patients/modules/new-bridge/modules/detailed-assessment/components/PdfDialog"
    ),
  loading: LoadingComponent,
});
const HealthGoals = Loadable({
  loader: () => import("./modules/misc/modules/health-goals"),
  loading: LoadingComponent,
});

const Escalations = Loadable({
  loader: () => import("modules/ops/modules/escalations"),
  loading: LoadingComponent,
});

const Login = Loadable({
  loader: () => import("modules/user/components/Login"),
  loading: LoadingComponent,
});
const DoctorLogin = Loadable({
  loader: () => import("modules/user/components/LoginAsDoctor"),
  loading: LoadingComponent,
});

const Profile = Loadable({
  loader: () => import("modules/user/components/Profile"),
  loading: LoadingComponent,
});

const Dashboard = Loadable({
  loader: () => import("modules/dashboard/components/Dashboard"),
  loading: LoadingComponent,
});

const Doctors = Loadable({
  loader: () => import("modules/sales/modules/doctors/components/Doctors"),
  loading: LoadingComponent,
});

const Consultations = Loadable({
  loader: () =>
    import("modules/ops/modules/consultations/components/Consultations"),
  loading: LoadingComponent,
});

const Feedbacks = Loadable({
  loader: () => import("modules/ops/modules/feedbacks/components/Feedbacks"),
  loading: LoadingComponent,
});

const Communications = Loadable({
  loader: () =>
    import("modules/ops/modules/communications/components/Communications"),
  loading: LoadingComponent,
});

const Patient = Loadable({
  loader: () => import("modules/ops/modules/patients/components/Patient"),
  loading: LoadingComponent,
});

const Miscellaneous = Loadable({
  loader: () => import("modules/misc/components/Miscellaneous"),
  loading: LoadingComponent,
});

const Units = Loadable({
  loader: () => import("modules/misc/modules/units/components/Units"),
  loading: LoadingComponent,
});

const Cuisines = Loadable({
  loader: () => import("modules/misc/modules/cuisines/components/Cuisines"),
  loading: LoadingComponent,
});

const Params = Loadable({
  loader: () => import("modules/misc/modules/params/components/Params"),
  loading: LoadingComponent,
});

const ParamGroups = Loadable({
  loader: () => import("modules/misc/modules/params/modules/groups"),
  loading: LoadingComponent,
});

const GoalsMaster = Loadable({
  loader: () => import("modules/misc/modules/goals/components/GoalsMaster"),
  loading: LoadingComponent,
});

const TasksMaster = Loadable({
  loader: () => import("modules/misc/modules/tasks/components/TasksMaster"),
  loading: LoadingComponent,
});

const ACL = Loadable({
  loader: () => import("modules/misc/modules/acl/components/ACL"),
  loading: LoadingComponent,
});

const InformationCards = Loadable({
  loader: () =>
    import("modules/ops/modules/information-cards/components/InformationCards"),
  loading: LoadingComponent,
});

const AddEditInformationCard = Loadable({
  loader: () =>
    import(
      "modules/ops/modules/information-cards/components/AddEditInformationCard"
    ),
  loading: LoadingComponent,
});

const ChecklistMasters = Loadable({
  loader: () =>
    import("modules/misc/modules/checklists/components/ChecklistMasters"),
  loading: LoadingComponent,
});

const ManagePatientTracking = Loadable({
  loader: () =>
    import(
      "modules/ops/modules/patients/modules/tracking/components/ManagePatientTracking"
    ),
  loading: LoadingComponent,
});

const PatientTracking = Loadable({
  loader: () =>
    import(
      "modules/ops/modules/patients/modules/tracking/components/PatientTracking"
    ),
  loading: LoadingComponent,
});

const DoctorBridgeView = Loadable({
  loader: () =>
    import("modules/ops/modules/patients/modules/doctor-bridge/index"),
  loading: LoadingComponent,
});

const ReportsAndVitalsCareManager = Loadable({
  loader: () => import("modules/ops/modules/patients/modules/new-bridge/index"),
  loading: LoadingComponent,
});

const ReportsAndVitals = Loadable({
  loader: () => import("modules/ops/modules/patients/modules/new-bridge/index"),
  loading: LoadingComponent,
});

const AddEditEmployee = Loadable({
  loader: () => import("modules/user/components/AddEditEmployee"),
  loading: LoadingComponent,
});

const Games = Loadable({
  loader: () => import("modules/recreation/modules/games"),
  loading: LoadingComponent,
});
const Protocols = Loadable({
  loader: () => import("modules/ops/modules/Protocols/Proto"),
  loading: LoadingComponent,
});

const DiseasesProtocols = Loadable({
  loader: () => import("modules/ops/modules/Protocols/Pages/DiseasesProtocols"),
  loading: LoadingComponent,
});
const SymptomsProtocols = Loadable({
  loader: () => import("modules/ops/modules/Protocols/Pages/SymptomsProtocols"),
  loading: LoadingComponent,
});
const AcuteconditionsProtocols = Loadable({
  loader: () =>
    import("modules/ops/modules/Protocols/Pages/AcuteconditionsProtocols"),
  loading: LoadingComponent,
});
const CareManagerProtocols = Loadable({
  loader: () =>
    import("modules/ops/modules/Protocols/Pages/CareManagerProtocols"),
  loading: LoadingComponent,
});

const MedicalConditions = Loadable({
  loader: () =>
    import(
      "./modules/misc/modules/medicalConditions/components/MedicalConditions"
    ),
  loading: LoadingComponent,
});

const Patients = Loadable({
  loader: () => import("modules/ops/modules/patients/"),
  loading: LoadingComponent,
});

const DoctorPatients = Loadable({
  loader: () => import("modules/ops/modules/patients/DoctorPatient"),
  loading: LoadingComponent,
});

const ContactZyla = Loadable({
  loader: () =>
    import(
      "./modules/ops/modules/patients/modules/doctor-bridge/contact/ContactZyla"
    ),
  loading: LoadingComponent,
});
const Prescription = Loadable({
  loader: () =>
    import(
      "./modules/ops/modules/patients/modules/new-bridge/modules/prescription"
    ),
  loading: LoadingComponent,
});
const CouponGenerator = Loadable({
  loader: () => import("./modules/ops/modules/coupon-generator"),
  loading: LoadingComponent,
});
class App extends Component {
  constructor() {
    super();
    this.environment = this.getEnvironment();
    this.environmentService = new EnvironmentService();
    this.environmentService.set(this.environment);

    this.authenticationService = new AuthenticationService();
    this.helper = new Helper();

    this.search = new URLSearchParams(window.location.search);

    // initialize Google Analytics only if production
    if (this.environmentService.isProduction()) {
      ReactGA.initialize("UA-96177847-2");
      this.logPageView();
    }

    this.state = {
      user: this.authenticationService.getUserProfile(),
      who: this.getWhoLoggedIn(),
    };
  }
  getWhoLoggedIn() {
    const user = this.authenticationService.getUserProfile();
    let who = "EMPLOYEE";
    if (user && user.patientProfile && user.patientProfile.id > 0) {
      who = "PATIENT";
    } else if (user && user.employeeProfile && user.employeeProfile.id > 0) {
      who = "EMPLOYEE";
    } else if (
      user &&
      user.employeeProfile &&
      user.employeeProfile.id > 0 &&
      user.doctorProfile &&
      user.doctorProfile.id > 0
    ) {
      who = "EMPLOYEE";
    } else if (user && user.doctorProfile && user.doctorProfile.id > 0) {
      who = "DOCTOR";
    } else {
      who = "EMPLOYEE";
    }
    return who;
  }
  componentWillMount() {
    this.getWhoLoggedIn();
    this.setState({
      user: this.authenticationService.getUserProfile(),
      who: this.getWhoLoggedIn(),
    });
  }
  getEnvironment() {
    let config = EnvironmentConfig;
    let originUrl = window.location.hostname;

    if (
      window.location.hostname == "feature.bridge.zyla.in" ||
      window.location.hostname == "staging-sy-doctor.zyla.in" ||
      window.location.hostname == "staging-zopper.zyla.in" ||
      window.location.hostname == "dev1-sy-doctor.zyla.in"
    ) {
      return "feature";
    }
    if (
      window.location.hostname == "doctor.zyla.in" ||
      window.location.hostname == "sy-doctor.zyla.in"
    ) {
      return "production";
    }

    for (var environment in config.vars) {
      let domain = config.vars[environment].domain;
      if (domain == originUrl) {
        return config.environments[environment];
      }
    }
  }

  logPageView() {
    ReactGA.set({ page: "home" });
    ReactGA.pageview("home");
  }

  render() {
    const updateStatus = localStorage.getItem("updateStatus");
    const updateMessage = localStorage.getItem("updateMessage");
    const { who } = this.state;

    return (
      <div className="App">
        <Router>
          <ScrollToTop>
            <div>
              <Switch>
                <Route path="/login" component={DoctorLogin} />
                <Route path="/" component={DoctorLogin}>
                  <div>
                    {who && who === "EMPLOYEE" && (
                      <Drawer>
                        <div className="container-main">
                          <ProtectedRoute
                            path="/"
                            component={Dashboard}
                            authenticator={this._authenticator}
                            redirectTo={"/login"}
                          />
                        </div>
                      </Drawer>
                    )}
                    {who && who === "DOCTOR" && (
                      <DoctorDrawer>
                        <ProtectedRoute
                          path="/"
                          component={DoctorPatients}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                        <ProtectedRoute
                          path="/me"
                          component={Profile}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                        <ProtectedRoute
                          path="/patients/:patientId/new-bridge"
                          component={DoctorBridgeView}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                        <ProtectedRoute
                          path="/patients/:patientId/new-bridge/health-plan"
                          component={DoctorBridgeView}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                        <ProtectedRoute
                          path="/contact"
                          component={ContactZyla}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                        <ProtectedRoute
                          path="/prescription/:userId"
                          component={Prescription}
                          authenticator={this._authenticator}
                          redirectTo={"/login"}
                        />
                      </DoctorDrawer>
                    )}
                  </div>
                </Route>

                <Route path="/not-found" component={NoPageFound} />
              </Switch>
              <GlobalError />
            </div>
          </ScrollToTop>
        </Router>
      </div>
    );
  }

  _authenticator = () => {
    return this.authenticationService.isLoggedIn();
  };

  _reverseAuthenticator = () => {
    return !this.authenticationService.isLoggedIn();
  };
}

export default App;
