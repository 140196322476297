import React from "react";
import { withRouter } from "react-router-dom";
import Button from "react-md/lib/Buttons";
import { Avatar } from "react-md";

import "./brand.css";

import Helper from "util/Helper";
import EventService from "service/event/EventService";
import AuthenticationService from "service/auth/AuthenticationService";

import logo from "assets/icons/zyla.png";

class Brand extends React.Component {
  constructor() {
    super();

    this.helper = new Helper();
    this.authenticationService = new AuthenticationService();

    this.state = {
      updateAvailable: false,
    };
  }

  componentDidMount() {
    EventService.on("updateAvailable", (args) => {
      this.setState({
        updateAvailable: true,
      });
    });
  }

  render() {
    return (
      <span className="container-brand">
        <span>
          <img
            src={logo}
            className="brand clickable"
            onClick={() => this.helper.navigateTo(this.props, "/")}
          />
          <span className="brand-text clickable">BRIDGE v1.5.18</span>
          {this.state.updateAvailable && (
            <Button
              flat
              className="tertiary"
              iconChildren="refresh"
              onClick={this._refresh}
            >
              New update available
            </Button>
          )}
        </span>
        <span className="container-user clickable" onClick={this._openProfile}>
          <Avatar
            src={this._getProfileImage()}
            role="presentation"
            className="avatar-user"
          />
          <span className="brand-text">
            &nbsp;{this.authenticationService.getUserName()}
          </span>
        </span>
      </span>
    );
  }

  _refresh = () => {
    window.location.reload();
  };

  _getProfileImage = () => {
    return this.authenticationService.getUserProfileImage();
  };

  _openProfile = () => {
    this.helper.navigateTo(this.props, "/me");
  };
}

export default withRouter(Brand);
