import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import WebFontLoader from "webfontloader";
// import injectTapEventPlugin from 'react-tap-event-plugin';
import "./styles/index.css";

// modules
import App from "./App";

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin();

WebFontLoader.load({
  google: {
    families: ["Varela Round", "Montserrat:400, 700", "Material Icons"],
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

registerServiceWorker();
window.addEventListener("beforeinstallprompt", (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
});
