import React from "react";
import FontIcon from "react-md/lib/FontIcons";
import proto from "../assets/icons/1CCopy.png";
import vacIcon from "../assets/icons/vaccine-icon.svg";
import msgSquare from "../assets/icons/vaccine-icon.svg";
import peopleIcon from "../assets/icons/people.svg";
import msgIcon from "../assets/icons/message-square-black.svg";
export const doctorNavItems = [
  {
    key: "/",
    primaryText: "My Patients",
    leftIcon: (
      <FontIcon id="proto_navigation">
        <img src={peopleIcon} width="20rem" height="20rem"></img>
      </FontIcon>
    ),
    active: true,
  },

  {
    key: "/contact",
    primaryText: "Contact Zyla Team",
    leftIcon: (
      <FontIcon id="proto_navigation">
        <img src={msgIcon} width="20rem" height="20rem"></img>
      </FontIcon>
    ),
    active: false,
  },
  {
    key: "/",
    primaryText: "",
    leftIcon: null,
    active: true,
  },
  {
    key: "1",
    primaryText: "Vx Calendar",
    leftIcon: (
      <FontIcon id="proto_navigation">
        <img src={vacIcon} width="20rem" height="20rem"></img>
      </FontIcon>
    ),
    active: true,
    isAzIcon:true
    
  },
 
];
export const navItems = [
  {
    key: "/",
    primaryText: "Home",
    leftIcon: <FontIcon id="home">home</FontIcon>,
    active: true,
  },
  {
    key: "/doctors",
    primaryText: "Doctors",
    leftIcon: <FontIcon id="doctor_navigation">inbox</FontIcon>,
    active: false,
  },
  {
    key: "/patients",
    primaryText: "Patients",
    leftIcon: <FontIcon id="patient_navigation">people</FontIcon>,
  },
  {
    key: "/sales",
    primaryText: "Sales",
    leftIcon: <FontIcon id="sales_navigation">filter_list</FontIcon>,
  },
  {
    key: "/feedbacks",
    primaryText: "Feedbacks",
    leftIcon: <FontIcon id="feedback_navigation">drafts</FontIcon>,
  },
  { key: "divider", divider: true },
  {
    key: "/communications",
    primaryText: "Communications",
    leftIcon: <FontIcon id="communication_navigation">mail</FontIcon>,
  },
  {
    key: "/care-manager",
    primaryText: "Care Manager",
    leftIcon: <FontIcon id="cm_navigation">sentiment_satisfied</FontIcon>,
  },
  {
    key: "care-manager-sales",
    primaryText: "Sales",
    leftIcon: <FontIcon id="salescm_navigation">star</FontIcon>,
  },
  {
    key: "/care-manager-az",
    primaryText: "Az-Cm",
    leftIcon: <FontIcon id="azcm_navigation">book</FontIcon>,
  },
  {
    key: "/medicine-orders",
    primaryText: "Medicine Orders",
    leftIcon: <FontIcon id="medicine_navigation">opacity</FontIcon>,
  },
  {
    key: "/information-cards",
    primaryText: "Info Cards",
    leftIcon: (
      <FontIcon id="infoCards_navigation">perm_device_information</FontIcon>
    ),
  },
  {
    key: "/miscellaneous",
    primaryText: "Miscellaneous",
    leftIcon: <FontIcon id="settings_navigation">settings</FontIcon>,
  },
  {
    key: "/escalations",
    primaryText: "Escalations",
    leftIcon: (
      <FontIcon id="escalation_navigation" className="tertiary">
        trending_up
      </FontIcon>
    ),
  },
  { key: "divider", divider: true },
  {
    key: "/games",
    primaryText: "Games",
    leftIcon: <FontIcon id="games_navigation">videogame_asset</FontIcon>,
  },
  {
    key: "/protocols",
    primaryText: "Protocols",
    leftIcon: (
      <FontIcon id="proto_navigation">
        <img src={proto} width="20rem" height="20rem"></img>
      </FontIcon>
    ),
  },
];
