import EnvironmentService from "service/environment/EnvironmentService";

class SessionManager {
  constructor() {
    this.environmentService = new EnvironmentService();
    this.cookieName = "auth_token";
    this.domainKeyName = "domain";
    this.domain = ".zyla.in";
  }

  /**
   * Set session in browser's store
   * @param {String} token [token to be stored in session]
   */
  setSession(token, minutes) {
    var expires = "";
    if (minutes) {
      var date = new Date(0);
      if (minutes > 0) {
        date = new Date();
        date.setTime(date.getTime() + minutes * 60 * 1000);
      }
      expires = "; expires=" + date.toGMTString();
    }
    var domain = "";
    if (this.domain) {
      if (this.environmentService.get() !== "development") {
        domain = "; " + this.domainKeyName + "=" + this.domain;
        domain = "; " + "domain" + "=" + ".zyla.in";
      }
    }
    document.cookie =
      this.cookieName + "=" + token + "; path=/;" + expires + domain;
  }

  /**
   * Get session stored in browser's store
   * @return {String}
   */
  getSession() {
    var cookie_list = document.cookie.split("; ");
    var cookies = {};

    for (var i = cookie_list.length - 1; i >= 0; i--) {
      var C = cookie_list[i].split("=");
      cookies[C[0]] = C[1];
    }

    return cookies[this.cookieName];
  }

  /**
   * Destroy session stored in browser's store
   */
  deleteSession() {
    //self.setSession(null, -1);
    var allcookies = document.cookie.split("; ");

    for (var i = 0; i < allcookies.length; i++) {
      var cookie = allcookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      var domain = "";
      if (this.domain) {
        if (this.environmentService.get() !== "development") {
          domain = "; " + this.domainKeyName + "=" + this.domain;
        }
      }
      if (this.cookieName === name) {
        document.cookie =
          name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT" + domain;
      }
    }
  }

  /**
   * Destroy session stored in browser's store
   */
  setCookie(name, value, minutes) {
    var expires = "";
    if (minutes) {
      var date = new Date(0);
      if (minutes > 0) {
        date = new Date();
        date.setTime(date.getTime() + minutes * 60 * 1000);
      }
      expires = "; expires=" + date.toGMTString();
    }
    var domain = "";
    if (this.domain) {
      if (this.environmentService.get() !== "development") {
        domain = "; " + "domain" + "=" + ".zyla.in";
      }
    }
    document.cookie = name + "=" + value + "; path=/;" + expires + domain;
  }
}

export default SessionManager;
