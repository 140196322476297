import React from "react";
import { withRouter } from "react-router-dom";
import NavigationDrawer from "react-md/lib/NavigationDrawers";
import SelectField from "react-md/lib/SelectFields";
import Avatar from "react-md/lib/Avatars";
import Helper from "util/Helper";
import EventService from "service/event/EventService";
import AuthenticationService from "service/auth/AuthenticationService";
import GlobalLoader from "components/common/GlobalLoader";
import ClientBrand from "components/brand/ClientBrand";
import AccountMenu from "components/account-menu/AccountMenu";
import { doctorNavItems } from "constants/Navigation";
import FontIcon from "react-md/lib/FontIcons";
import vaccineInfo from "../../../src/assets/images/vaccine-info1.png";
import "./drawer.css";
import azlogo from "../../assets/icons/AZLOGO.png";
import logo from "../../assets/icons/zyla-logo.svg";

class DoctorDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.helper = new Helper();
    this.authenticationService = new AuthenticationService();

    this.state = {
      visible: false,
      position: "left",
      user: this.authenticationService.getUserProfile(),
      showVacPoster: false,
    };
    this.doctorCode = JSON.parse(localStorage.getItem("zy.ACCOUNT_CODE"));

    let currentDocItem = doctorNavItems;

    if (this.doctorCode !== "03XXNA" && this.doctorCode !== "03XXND") {
      currentDocItem = currentDocItem.filter((ele) => !ele.isAzIcon);
    }

    this._navItems = currentDocItem.map((item) => {
      if (!item.divider) {
        item.onClick = () => {
          this._setPage(item.key);
          if (item.key != 1) {
            window.location.reload();
          }
        };
      }
      return item;
    });
  }

  componentDidMount() {
    const { user } = this.state;

    if (
      user.doctorProfile &&
      (JSON.parse(localStorage.getItem("zy.ACCOUNT_CODE")) === "03XXAZ" ||
        JSON.parse(localStorage.getItem("zy.ACCOUNT_CODE")) === "03XXHH")
    ) {
      this._navItems.push({
        active: false,
        key: "/contact123",
        leftIcon: "",
        primaryText: (
          <div className="zyla-az-message">
            <div className="text-drawer">
              This is a patient-centric initiative supported by
            </div>
            <div className="image-drawer">
              {" "}
              <img src={azlogo}></img>{" "}
            </div>
          </div>
        ),
      });
    }
    EventService.on("updateNavigation", (args) => {
      this._activatePage(args.key);
    });
    let name = "User";
    if (user && user.employeeProfile) {
      name = user.employeeProfile.name;
    } else if (user && user.doctorProfile) {
      name = user.doctorProfile.name;
    }
    window.addEventListener("openVacPoster", this.handleShowVacPosterOpen);
  }

  componentWillUnmount() {
    window.removeEventListener("openVacPoster", this.handleShowVacPosterOpen);
  }

  handleShowVacPosterOpen = () => {
    this.setState({ showVacPoster: true });
  };
  handleShowVacPosterClose = () => {
    this.setState({ showVacPoster: false });
  };

  render() {
    const user = this.state.user;
    let name = "User";
    if (user && user.employeeProfile) {
      name = user.employeeProfile.name;
    } else if (user && user.doctorProfile) {
      name = user.doctorProfile.name;
    }

    const drawerHeaderChildren = [
      <div style={{ marginLeft: "20px", cursor: "pointer", display: "flex" }}>
        <br />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={logo}
            className="brand clickable"
            onClick={() => this.helper.navigateTo(this.props, "/")}
          />
        </div>

        {/* <h5
          onClick={() => {
            window.location = "/";
          }}
        >
          Patient Dashboard
        </h5> */}
      </div>,
    ];

    const toolbarActions = (
      <div>
        <AccountMenu
          simplifiedMenu={false}
          image={null}
          name={"User"}
          onClickLogout={this._logout}
        />
      </div>
    );

    return (
      <NavigationDrawer
        navItems={this._navItems}
        contentClassName="md-grid"
        drawerHeaderChildren={drawerHeaderChildren}
        mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
        tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
        desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
        toolbarTitle={<ClientBrand onClickLogout={this._logout} />}
        toolbarActions={null}
        toolbarChildren={<GlobalLoader />}
        toolbarThemeType={"themed"}
        toolbarClassName={"title-bar"}
        contentId="main-content"
        className="main-drawer-conatiner"
      >
        <div style={{ padding: "0 15px" }}>{this.props.children}</div>
        {this.state.showVacPoster && (
          <div className="vaccine-information-drawer">
            <div className="vaccine-information-drawer-box ">
              <FontIcon
                className="vaccine-information-box-top"
                onClick={this.handleShowVacPosterClose}
              >
                close
              </FontIcon>
              <img
                src={vaccineInfo}
                className="vaccine-information-boc-image"
              />
            </div>
          </div>
        )}
      </NavigationDrawer>
    );
  }

  _setPage = (key) => {
    this._navItems = this._navItems.map((item) => {
      if (!item.divider) {
        item.active = item.key === key;
      }
      return item;
    });

    this.helper.navigateTo(this.props, key);
  };

  _activatePage = (key) => {
    this._navItems = this._navItems.map((item) => {
      if (!item.divider) {
        item.active = item.key === key;
      }
      return item;
    });
  };

  _logout = () => {
    this.authenticationService.logout();
    this.helper.navigateTo(this.props, "/login");
  };
}

export default withRouter(DoctorDrawer);
