import AuthenticationService from "service/auth/AuthenticationService";
import Helper from "util/Helper";
import newApiService from "./newAPIService";

class ApiService {
  get(url, onResponse, onError) {
    return newApiService.get(url, onResponse, onError);
  }

  getUpcoming(url, onResponse, onError) {
    return newApiService.getUpcoming(url, onResponse, onError);
  }

  getImage(url, onResponse, onError) {
    return newApiService.getImage(url, onResponse, onError);
  }

  post(url, params, onResponse, onError) {
    return newApiService.post(url, params, onResponse, onError);
  }

  postV2(url, payload, customHeaders, onResponse, onError) {
    return newApiService.postV2(
      url,
      payload,
      customHeaders,
      onResponse,
      onError
    );
  }

  postWithFormData(url, params, onResponse, onError) {
    return newApiService.postWithFormData(url, params, onResponse, onError);
  }

  patchtWithFormData(url, params, onResponse, onError) {
    return newApiService.patchtWithFormData(url, params, onResponse, onError);
  }

  putWithFormData(url, params, onResponse, onError) {
    return newApiService.putWithFormData(url, params, onResponse, onError);
  }

  put(url, params, onResponse, onError) {
    return newApiService.put(url, params, onResponse, onError);
  }

  patch(url, params, onResponse, onError) {
    return newApiService.patch(url, params, onResponse, onError);
  }

  delete(url, onResponse, onError) {
    return newApiService.delete(url, onResponse, onError);
  }
}

export default ApiService;
