import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';

import Helper from 'util/Helper';

class ProtectedRoute extends React.Component {
  constructor() {
    super();

    this.helper = new Helper();
  }

  render() {
    if (this.props.authenticator()) {
      return(
        <Route exact {...this.props} />
      );
    } else {
      return(
        <Redirect to={ this.props.redirectTo } />
      );
    }
  }
}

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  authenticator: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired
};

export default withRouter(ProtectedRoute);
