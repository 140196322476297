import AuthenticationService from "service/auth/AuthenticationService";
import Helper from "util/Helper";
import APIConfig from "./APIConfig";

class newApiService {
  constructor() {
    this.KEY_CLIENT = "client";
    this.KEY_PHONENO = "phone_no";
    this.KEY_TOKEN = "auth_token";

    this.CLIENT = "bridge_web";
    this.DOCTOR_CLIENT = "doctor_web";

    this.authenticationService = new AuthenticationService();
    this.helper = new Helper();

    this.initializeHeaders();
  }

  async saveNewAccessToken() {
    let url = APIConfig.doctor.newAccessToken;
    let body = JSON.parse(localStorage.getItem("zy.USER_PROFILE"))?.accessToken;
    body.accessToken = "";
    let response = (res) => {
      let user = JSON.parse(localStorage.getItem("zy.USER_PROFILE"));
      user.accessToken = res;
      localStorage.setItem("zy.USER_PROFILE", JSON.stringify(user));
    };
    let error = (err) => {
      this.doLogOut();
    };
    await this.postv3(url, body, response, error);
    return;
  }
  async saveNewDocToken() {
    let url = APIConfig.documentApis.tokenGenerate;
    let response = (res) => {
      localStorage.setItem("zy.bridge.doc.token", JSON.stringify(res));
    };
    let error = (err) => {
      console.log("Error in getting new doc token: ", err);
    };
    await this.getv3(url, response, error);
    return;
  }
  doLogOut() {
    this.authenticationService.logout();
    return;
  }

  setNewToken() {
    const newTokenPromise = new Promise((resolve, reject) => {
      this.saveNewAccessToken()
        .then(() => {
          this.initializeHeaders();
          this.saveNewDocToken()
            .then(() => {
              resolve();
            })
            .catch(() => {
              resolve();
            });
        })
        .catch(() => {
          this.doLogOut();
        });
    });

    return newTokenPromise;
  }

  initializeHeaders() {
    let user = this.authenticationService.getUserProfile();
    let phoneNo = this.authenticationService.isLoggedIn() ? user.phoneno : "";
    let token = this.authenticationService.isLoggedIn()
      ? JSON.parse(localStorage.getItem("zy.USER_PROFILE"))?.accessToken
          ?.accessToken
      : "";
    let isDoctor = false;
    if (user && user.employeeProfile && user.employeeProfile.id > 0) {
      isDoctor = false;
    } else if (
      user &&
      user.employeeProfile &&
      user.employeeProfile.id > 0 &&
      user.doctorProfile &&
      user.doctorProfile.id > 0
    ) {
      isDoctor = false;
    } else if (user && user.doctorProfile && user.doctorProfile.id > 0) {
      isDoctor = true;
    } else {
      isDoctor = false;
    }

    this.headers = {
      client: isDoctor ? this.DOCTOR_CLIENT : this.CLIENT,
      "Content-Type": "application/json",
    };

    this.smsServiceHeaders = {
      access_token: "7174e133-e936-4a38-a789-a7d2cff665a3",
      client: isDoctor ? this.DOCTOR_CLIENT : this.CLIENT,
      "Content-Type": "application/json",
    };

    if (phoneNo !== "") {
      this.headers.phone_no = phoneNo;
    }

    if (token !== "") {
      this.headers.auth_token = token;
    }
  }

  get(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.getAgain(url, onResponse, onError);
          });
          return;
        } else if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  getAgain(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  getUpcoming(url, onResponse, onError) {
    let that = this;

    let headers = {
      origin: "*",
    };

    return fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.getUpcomingAgain(url, onResponse, onError);
          });
          return;
        } else if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  getUpcomingAgain(url, onResponse, onError) {
    let that = this;

    let headers = {
      origin: "*",
    };

    return fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  getImage(url, onResponse, onError) {
    let that = this;

    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };

    return fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.getImageAgain(url, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // return the buffer
        response.arrayBuffer().then((buffer) => {
          onResponse(buffer);
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  getImageAgain(url, onResponse, onError) {
    let that = this;

    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };

    return fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // return the buffer
        response.arrayBuffer().then((buffer) => {
          onResponse(buffer);
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  post(url, params, onResponse, onError) {
    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.postAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postAgain(url, params, onResponse, onError) {
    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  postV2(url, payload, customHeaders, onResponse, onError) {
    let that = this;

    let headersToSend = "";
    if (customHeaders === null) {
      headersToSend = this.headers;
    } else if (customHeaders === "sms-service") {
      headersToSend = this.smsServiceHeaders;
    }

    return fetch(url, {
      method: "POST",
      headers: headersToSend,
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.postV2Again(url, payload, customHeaders, onResponse, onError);
          });
          return;
        } else if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postV2Again(url, payload, customHeaders, onResponse, onError) {
    let that = this;

    let headersToSend = "";
    if (customHeaders === null) {
      headersToSend = this.headers;
    } else if (customHeaders === "sms-service") {
      headersToSend = this.smsServiceHeaders;
    }

    return fetch(url, {
      method: "POST",
      headers: headersToSend,
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  getv3(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 204) {
          return onResponse(undefined);
        }
        if (response.status !== 200) {
          return response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        return response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  //postv3 used for getting new access token
  postv3(url, params, onResponse, onError) {
    let that = this;
    let newHeader = this.headers;
    delete newHeader[this.KEY_TOKEN];

    return fetch(url, {
      method: "POST",
      headers: newHeader,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          return response.json().then(function (data) {
            onResponse(data);
          });
        }
        if (response.status !== 200) {
          return response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
        }

        // Examine the text in the response
        return response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  postWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.postWithFormDataAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postWithFormDataAgain(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  patchtWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PATCH",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.patchtWithFormDataAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  patchtWithFormDataAgain(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PATCH",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  putWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PUT",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.putWithFormDataAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  putWithFormDataAgain(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PUT",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  put(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.putAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  putAgain(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  patch(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.patchAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  patchAgain(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  delete(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "DELETE",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.deleteAgain(url, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Goal deleted.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  deleteAgain(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "DELETE",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Goal deleted.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
}

export default new newApiService();
