import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class PatientsService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
    this.doctorCode = JSON.parse(localStorage.getItem("zy.ACCOUNT_CODE"));
  }

  new(patient, onResponse, onError) {
    let url = APIConfig.patient.new;

    return this.apiService.post(url, patient, onResponse, onError);
  }

  get(patientId, onResponse, onError) {
    let url = APIConfig.patient.get.format(patientId);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.patient.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  searchFromDoctor(params, onResponse, onError) {
    let url = APIConfig.patient.searchFromDoctor.format(
      params.query,
      params.type,
      params.from,
      params.size
    );
    return this.apiService.get(url, onResponse, onError);
  }

  // New API to get only Parents in case of ND and old API in case of other doctor codes
  searchParentsFromDoctor(params, onResponse, onError) {
    // console.log("istbmview is:", isTBMView)
    // let url = APIConfig.patient.searchParentsFromDoctor.format(
    // let url = isTBMView === true ?
    let url =
      this.doctorCode !== "03XXND"
        ? APIConfig.patient.searchFromDoctor.format(
            params.query,
            params.type,
            params.from,
            params.size
          )
        : APIConfig.patient.searchParentsFromDoctor.format(
            params.query,
            params.type,
            params.from
            // params.size
          );
    return this.apiService.get(url, onResponse, onError);
  }

  search(search, onResponse, onError) {
    let url;
    // search
    if (search.type === 3)
      url = APIConfig.patient.searchPhoneno.format(
        search.query,
        search.type,
        search.paid,
        search.cc
      );
    else
      url = APIConfig.patient.search.format(
        search.query,
        search.type,
        search.paid
      );
    return this.apiService.get(url, onResponse, onError);
  }

  getPatient(patientId, onResponse, onError) {
    let url = APIConfig.patient.get.format(patientId);

    return this.apiService.get(url, onResponse, onError);
  }

  updateParams(patientId, patient, onResponse, onError) {
    let url = APIConfig.patient.updateParams + patientId + "/params";

    return this.apiService.put(url, patient, onResponse, onError);
  }

  updateParamGroups(patientId, patient, onResponse, onError) {
    let url = APIConfig.patient.updateParamGroups.format(patientId);

    return this.apiService.put(url, patient, onResponse, onError);
  }

  getTrackingInfo(patientId, doctorId, onResponse, onError) {
    let url = APIConfig.patient.getTrackingInfo.format(patientId, doctorId);

    return this.apiService.get(url, onResponse, onError);
  }

  changeStatus(patientId, patientRequest, onResponse, onError) {
    let url = APIConfig.patient.updateStatus.format(patientId);

    return this.apiService.put(url, patientRequest, onResponse, onError);
  }

  invite(patientId, onResponse, onError) {
    let url = APIConfig.patient.invite.format(patientId);

    return this.apiService.put(url, {}, onResponse, onError);
  }

  updateEmail(patientId, patientRequest, onResponse, onError) {
    let url = APIConfig.patient.updateEmail.format(patientId);
    return this.apiService.putWithFormData(
      url,
      patientRequest,
      onResponse,
      onError
    );
  }
  getPrescription(patientId, onResponse, onError) {
    let url = APIConfig.patient.prescription.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }

  verifyPrescription(patientId, status, commentType, onResponse, onError) {
    let url = APIConfig.patient.verifyStatus.format(patientId);
    return this.apiService.put(
      url,
      {
        status: status,
        commentType: commentType,
      },
      onResponse,
      onError
    );
  }
  getReferrer(patientId, onResponse, onError) {
    let url = APIConfig.patient.referrer.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }
  updateReferrer(patientId, docCode, onResponse, onError) {
    let url = APIConfig.patient.referrer.format(patientId);

    return this.apiService.put(
      url,
      { doctorCode: docCode },
      onResponse,
      onError
    );
  }
  getCount(onResponse, onError) {
    let url = APIConfig.doctor.patientCount;

    return this.apiService.get(url, onResponse, onError);
  }
  getPrimaryTherapyInfo(patientId, onResponse, onError) {
    let url =
      "https://services.prod.zyla.in/v2/pa/assessments/" +
      patientId +
      "/primary";
    if (!this.environmentService.isProduction()) {
      url = APIConfig.patient.primaryTherapy.format(patientId);
    }
    return this.apiService.get(url, onResponse, onError);
  }
  fetchLanguage(onResponse, onError) {
    let url = APIConfig.language.get;
    return this.apiService.get(url, onResponse, onError);
  }
  fetchFollowUp(patientId, onResponse, onError) {
    var size = 1;
    var pageNo = 1;
    let url = APIConfig.followUp.get.format(patientId, pageNo, size);
    return this.apiService.get(url, onResponse, onError);
  }

  fetchLatestProfile(patientId, onResponse, onError) {
    let url = APIConfig.patient.latestUserProfile.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }
  fetchDeliveryDate(id, onResponse, onError) {
    let url = APIConfig.patient.deliveryDate.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  //get all minor member profiles
  getMinorMember(data, onResponse, onError) {
    let url = APIConfig.family.getMinorMember;
    return this.apiService.post(url, data, onResponse, onError);
  }

  //fetch Dose details
  getDrugSchedule(patientId, onResponse, onError) {
    let url = APIConfig.doseDetails.getDrugSchedule.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }

  //fetch all vaccines list
  fetchVaccines = (onResponse, onError) => {
    let url = APIConfig.vaccine.getAllVaccine;
    return this.apiService.get(url, onResponse, onError);
  };

  //generate and save document token
  getAndSaveDocumentToken = (onResponse, onError) => {
    let url = APIConfig.documentApis.tokenGenerate;
    return this.apiService.get(url, onResponse, onError);
  };

  //fetch alll vitals
  fetchLatestVitals(id, onResponse, onError) {
    let url = APIConfig.vital.latestVital.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  //fetch all appointments
  fetchAppointments(id, onResponse, onError) {
    let url = APIConfig.navigoDashboard.allAppointments.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  fetchWebinars(id, onResponse, onError) {
    let url = APIConfig.navigoDashboard.allWebinars;
    return this.apiService.getUpcoming(url + "/" + id, onResponse, onError);
  }
}

export default PatientsService;
