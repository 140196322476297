import StorageService from "service/storage/StorageService";
import Config from "./EnvironmentConfig";

class EnvironmentService {
  constructor(environment) {
    this.storageService = new StorageService();
    this.config = Config;

    if (
      window.location.hostname === "doctor.zyla.in" ||
      window.location.hostname === "sy-doctor.zyla.in"
    ) {
      this.environment = this.storageService.get("ENVIRONMENT") || "production";
    } else if (
      window.location.hostname === "feature.bridge.zyla.in" ||
      window.location.hostname === "staging-sy-doctor.zyla.in" ||
      window.location.hostname === "staging-zopper.zyla.in" ||
      window.location.hostname === "dev1-sy-doctor.zyla.in"
    ) {
      this.environment = this.storageService.get("ENVIRONMENT") || "feature";
      // this.environment = "production";
    } else {
      this.environment =
        this.storageService.get("ENVIRONMENT") || "development";
      // this.environment = "production";
    }
  }

  get() {
    return this.environment;
  }

  set(environment) {
    this.environment = environment;
    this.storageService.set("ENVIRONMENT", this.environment);
  }

  isProduction() {
    return this.environment === this.config.environments.production;
  }

  getBaseAPI() {
    return this.config.vars[this.environment].apiUrl;
  }

  getServiceAPI() {
    return this.config.vars[this.environment].serviceUrl;
  }
  getServiceAPINew() {
    console.log(
      this.config.vars[this.environment].serviceNew,
      this.config.vars,
      this.environment
    );
    return this.config.vars[this.environment].serviceNew;
  }

  getServiceSocket() {
    return this.config.vars[this.environment].serviceSocket;
  }

  getDomain() {
    return this.config.vars[this.environment].domain;
  }

  getStrapiAPI() {
    return this.config.vars[this.environment].serviceStrapi;
  }
}

export default EnvironmentService;
