const ENVIRONMENT_CONFIG = {
  domains: {
    development: ["localhost", "zyla.in"],
    feature: ["zyla.in"],
    production: ["zyla.in"],
  },
  vars: {
    development: {
      apiUrl: "https://dev1.api.zyla.in",
      domain: "localhost",
      serviceUrl: "https://services.dev1.zyla.in",
      serviceSocket: "wss://services.dev1.zyla.in",
      serviceNew: "https://services.dev1.zyla.in",
      staticUrl: "https://dev1.api.zyla.in",
      serviceStrapi: "https://staging.strapi.zyla.in",
    },
    feature: {
      domain: "feature.bridge.zyla.in",
      apiUrl: "https://dev1.api.zyla.in",
      staticUrl: "https://dev1.api.zyla.in",
      serviceUrl: "https://services.dev1.zyla.in",
      serviceSocket: "wss://services.dev1.zyla.in",
      serviceNew: "https://services.dev1.zyla.in",
      serviceStrapi: "https://staging.strapi.zyla.in",
    },
    production: {
      apiUrl: "//api.zyla.in",
      staticUrl: "//api.zyla.in",
      domain: "doctor.zyla.in",
      serviceUrl: "//services.prod.zyla.in",
      serviceSocket: "wss://services.prod.zyla.in",
      serviceNew: "https://services.prod.zyla.in",
      serviceStrapi: "https://strapi.prod.zyla.in",
    },
  },
  environments: {
    development: "development",
    feature: "feature",
    production: "production",
  },
};

export default ENVIRONMENT_CONFIG;
