import React from "react";
import { withRouter, Link } from "react-router-dom";
import Button from "react-md/lib/Buttons";
import { Avatar, MenuButton, ListItem } from "react-md";

import "./brand.css";

import Helper from "util/Helper";
import EventService from "service/event/EventService";
import AuthenticationService from "service/auth/AuthenticationService";

import logo from "assets/icons/zyla-logo.svg";
import { Col, Row } from "react-flexbox-grid";
import ReliableRolemodel from "../../assets/images/ReliableRolemodel.svg";
import doctorwithstethoscope from "../../assets/images/doctorwithstethoscope .svg";
import "./client-brand.css";
import badge1 from "assets/images/BadgeproudPioneer.svg";
import badgeSuper from "assets/images/BadgeSS.svg";
import badgeRR from "assets/images/BadgeRR.svg";

import badgeTB from "assets/images/BadgeTB.svg";
import badgeTT from "assets/images/BadgeTT.svg";
import badgeD from "assets/images/BadgeD.svg";
import badgeHH from "assets/images/BadgeHH.svg";
import badgePP from "assets/images/BadgePP.svg";
import zydusLogo from "../../assets/icons/zydus-logo.jpg";
import blackArrow from "../../assets/images/blackdownarrow.svg";
import PatientService from "../../modules/ops/modules/patients/service/PatientsService";

class ClientBrand extends React.Component {
  constructor() {
    super();
    this.patientsService = new PatientService();
    this.helper = new Helper();
    this.authenticationService = new AuthenticationService();
    this.resourcebadge = badgeSuper;

    this.state = {
      copy: "Copy",
      updateAvailable: false,
      user: this.authenticationService.getUserProfile(),

      isZydus: JSON.parse(localStorage.getItem("zy.ACCOUNT_CODE")) == "04XXZY",
    };
  }

  componentDidMount() {
    EventService.on("updateAvailable", (args) => {
      this.setState({
        updateAvailable: true,
      });
    });
    // this.fetchCount();
  }

  fetchCount() {
    this.patientsService.getCount(
      (res) => {
        this.setState({ totalPatient: res });
        this.chooseBadge(res);
      },
      () => {
        this.setState({ totalPatient: 0 });
      }
    );
  }

  render() {
    const { onClickLogout } = this.props;
    const { user, isZydus } = this.state;
    const { doctorProfile } = user;

    return (
      <div className="container-brand">
        <img
          src={logo}
          className="brand clickable"
          onClick={() => this.helper.navigateTo(this.props, "/")}
        />
        <div className="container-brand-right">
          {isZydus && (
            <div className="container-client-brand-logo ">
              <img src={zydusLogo} className="" />
            </div>
          )}
          {/* <div className="profile-container">
            <div className="img-container">
              <Link to="/me">
                <Avatar
                  src={this._getProfileImage()}
                  role="presentation"
                  className="avatar-user"
                ></Avatar>
              </Link>
            </div>
            <Link to="/me">
              <div className="doctor-user">
                <span>{doctorProfile.name}</span>
                <span className="doctor-user-title">
                  {doctorProfile.clinicHospital} | {doctorProfile.location}
                </span>
              </div>
            </Link>
          </div> */}

          <div className="setting-container">
            {/* <div className="client-name">
              <div className="brand-text clickable">Beyond Glucose Program</div>
            </div> */}

            <div className="logout-link">
              <MenuButton
                // secondary
                // raised
                // style={{ left: "22rem" }}
                className="profile-btn"
                simplifiedMenu={false}
                menuItems={[
                  // <ListItem
                  //   // primaryText="Save"
                  //   style={{ height: "250px", width: "250px" }}
                  // >

                  <div className="doc-profile-container">
                    <Avatar
                      src={this._getProfileImage()}
                      role="presentation"
                      className="avatar-user"
                    ></Avatar>
                    {/* <img src={this.resourcebadge} width="150px"></img> */}
                    <label className="doc-name"> {doctorProfile.name}</label>
                    <label className="doc-details-logout">
                      {" "}
                      {doctorProfile.clinicHospital} | {doctorProfile.location}{" "}
                    </label>

                    <div
                      style={{
                        marginTop: "28px",
                        border: "1px solid #979797",
                        borderRadius: "4px",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #979797",
                          padding: "10px",
                        }}
                      >
                        <div>
                          <label>My Doctor Code</label>
                          <label
                            style={{
                              color: "white",
                              backgroundColor: "#ef7c01",
                              borderRadius: "12px",
                              marginLeft: "14px",
                              padding: "2px",
                              cursor: "pointer",
                              padding: "4px",
                            }}
                            onClick={() => {
                              this.setState({ copy: "Copied" });
                              setTimeout(() => {
                                this.setState({ copy: "Copy" });
                              }, 2000);
                              var text = doctorProfile.code;
                              navigator.clipboard.writeText(text).then(
                                function () {},
                                function (err) {}
                              );
                            }}
                          >
                            {this.state.copy}
                          </label>
                        </div>

                        <div className="profile-content">
                          {doctorProfile.code}
                        </div>
                      </div>
                      {/* <div
                        style={{
                          borderBottom: "1px solid #979797",
                          padding: "10px",
                        }}
                      > */}
                      {/* <div>
                          <label>My VCC Website</label>
                          <label
                            style={{
                              color: "white",
                              backgroundColor: "#ef7c01",
                              borderRadius: "12px",
                              marginLeft: "14px",
                              padding: "2px",
                            }}
                            onClick={() => {
                              var text = "Example text to appear on clipboard";
                              navigator.clipboard.writeText(text).then(
                                function () {
                                  console.log(
                       logout-link                             "Async: Copying to clipboard was successful!"
                                  );
                                },
                                function (err) {
                                  console.error(
                                    "Async: Could not copy text: ",
                                    err
                                  );
                                }
                              );
                     
                            }}
                          >
                            copy
                          </label>
                        </div> */}

                      {/* <div className="profile-content">
                          {doctorProfile.code}
                        </div> */}
                      {/* </div> */}
                    </div>
                    <Button
                      onClick={() => onClickLogout()}
                      style={{ width: "100%" }}
                      raised
                      secondary
                    >
                      Log out
                    </Button>
                  </div>,
                ]}
              >
                <div className="profile-container doctor-profile-detail-container">
                  <div className="img-container">
                    <img src={this._getProfileImage()} />
                  </div>

                  <div
                    className="doctor-user"
                    style={{ justifyContent: "center" }}
                  >
                    <span>{doctorProfile.name}</span>
                    {/* <span className="doctor-user-title">
                  {doctorProfile.clinicHospital} | {doctorProfile.location}
                </span> */}
                  </div>
                  <img
                    src={blackArrow}
                    className="doctor-profile-detail-arrow"
                  />
                </div>
              </MenuButton>
              {/* <strong onClick={() => onClickLogout()}>Logout</strong> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  render1() {
    const { onClickLogout } = this.props;
    const { user } = this.state;
    const { doctorProfile } = user;
    return (
      <div className="container-brand container-client-brand ">
        <div className="clickable">
          <Row>
            <Avatar
              src={this._getProfileImage()}
              role="presentation"
              className="avatar-user"
            />
            <Col className="doctor-user">
              <Row>
                <span className="doctor-user-name">{doctorProfile.name}</span>
              </Row>
              <Row>
                <span className="doctor-user-title">
                  {doctorProfile.clinicHospital} | {doctorProfile.location}
                </span>
              </Row>
            </Col>
          </Row>
        </div>

        <Row className="no-mg">
          <Col className="p-15 no-mg">
            <Row className="no-mg">
              <strong className="brand-text clickable">
                Beyond Glucose Program
              </strong>
            </Row>
            <Row className="logout-link no-mg" onClick={() => onClickLogout()}>
              Logout
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
  revert() {
    this.setState({
      copy: "Copy",
    });
  }
  _refresh = () => {
    window.location.reload();
  };

  _getProfileImage = () => {
    let image = this.authenticationService.getUserProfileImage();

    return image;
  };

  _openProfile = () => {
    this.helper.navigateTo(this.props, "/me");
  };
  chooseBadge(count) {
    switch (true) {
      case count < 2:
        this.resourcebadge = badge1;
        // maxCount = 2;
        break;
      case count < 5:
        this.resourcebadge = badgeTB;
        // maxCount = 5;
        break;
      case count < 10:
        this.resourcebadge = badgeTT;
        // maxCount = 10;
        break;
      case count < 25:
        this.resourcebadge = badgeD;
        // maxCount = 25;
        break;
      case count < 50:
        this.resourcebadge = badgeRR;
        // maxCount = 50;
        break;
      case count < 75:
        this.resourcebadge = badgePP;
        // maxCount = 75;
        break;
      case count < 100:
        this.resourcebadge = badgeHH;
        // maxCount = 100;
        break;
      default:
        this.resourcebadge = badgeSuper;

        break;
    }
  }
}

export default withRouter(ClientBrand);
